<template>
    <TransitionGroup tag="div" name="notification" class="notification">
        <AppNotification
            v-for="notification of app.notifications"
            :key="notification.id"
            :message="notification.message"
            :type="notification.type"
            :icon="notification.icon"
            :hide-icon="notification.hideIcon"
            @dismiss="app.removeNotification(notification.id)"
        />
    </TransitionGroup>
</template>

<script setup lang="ts">
import AppNotification from './AppNotification.vue'
import { useAppStore } from '../../stores/app'

const app = useAppStore()
</script>

<style lang="scss">
.notification {
    position: fixed;
    bottom: 0;
    display: flex;
    flex-direction: column;
    align-items: flex-end;
    justify-content: center;
    right: 0;
    padding: 0.5rem;
    z-index: $zindex-toast;
}

.notification-enter-active,
.notification-leave-active {
    transition: all 200ms ease;
}

.notification-enter-from,
.notification-leave-to {
    opacity: 0;
    transform: translateY(-10%);
}
</style>
