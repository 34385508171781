import { createRouter as createVueRouter, createWebHistory, isNavigationFailure, RouteComponent } from 'vue-router'
import { useAppStore } from '../stores/app'
import { useAuthStore } from '../stores/auth'
import { hasFlag, hasPermission } from '../utilities/auth'
import { HttpError } from '../utilities/error'
import DashboardView from '../components/dashboard/DashboardView.vue'
import RecentlyViewedView from '../components/dashboard/RecentlyViewedView.vue'
import LoginView from '../components/auth/LoginView.vue'
import LogoutView from '../components/auth/LogoutView.vue'
import NotFoundView from '../components/NotFoundView.vue'

declare module 'vue-router' {
    interface RouteMeta {
        flags?: { [key: string]: boolean } | undefined | null
        permissions?: string[]
        inversePermissions?: string[]
        public?: boolean
    }
}

const routes = [
    {
        name: 'dashboard',
        path: '/',
        // Prevent lazyload to prevent flash?
        component: DashboardView,
        meta: {
            permissions: ['FunderReferenceViewer'],
        },
    },
    {
        name: 'recently-viewed',
        path: '/recently-viewed',
        // Prevent lazyload to prevent flash?
        component: RecentlyViewedView,
        meta: {
            inversePermissions: ['FunderReferenceViewer'],
        },
    },
    {
        name: 'property-search',
        path: '/property',
        component: (): Promise<RouteComponent> => import('../components/property/PropertySearchBaseView.vue'),
        children: [
            {
                name: 'search',
                path: '',
                component: (): Promise<RouteComponent> => import('../components/property/SearchView.vue'),
            },
            {
                name: 'property',
                path: 'view',
                component: (): Promise<RouteComponent> => import('../components/property/PropertyView.vue'),
            },
        ],
    },
    {
        name: 'account',
        path: '/account',
        component: (): Promise<RouteComponent> => import('../components/account/AccountView.vue'),
    },
    {
        name: 'funder-references',
        path: '/funder-references',
        component: (): Promise<RouteComponent> => import('../components/funderReference/FunderReferencesBaseView.vue'),
        meta: {
            permissions: ['FunderReferenceViewer'],
        },
        children: [
            {
                name: 'funder-references-list',
                path: '',
                component: (): Promise<RouteComponent> =>
                    import('../components/funderReference/FunderReferencesView.vue'),
            },
            {
                name: 'funder-reference-new',
                path: 'new',
                component: (): Promise<RouteComponent> =>
                    import('../components/funderReference/FunderReferenceView.vue'),
                meta: {
                    permissions: ['FunderReferenceEditor'],
                },
            },
            {
                name: 'funder-reference-new-bulk',
                path: 'bulk',
                component: (): Promise<RouteComponent> =>
                    import('../components/funderReference/FunderReferenceBulkView.vue'),
                meta: {
                    permissions: ['FunderReferenceEditor'],
                },
            },
            {
                name: 'funder-reference',
                path: ':funderReference',
                component: (): Promise<RouteComponent> =>
                    import('../components/funderReference/FunderReferenceView.vue'),
            },
            {
                name: 'funder-reference-bulk-upload-history',
                path: 'bulk-upload-history',
                component: (): Promise<RouteComponent> =>
                    import('../components/funderReference/FunderReferenceBulkUploadHistoryView.vue'),
            },
        ],
    },
    {
        name: 'users',
        path: '/users',
        component: (): Promise<RouteComponent> => import('../components/users/UsersView.vue'),
        meta: {
            permissions: ['FunderUserManagement'],
        },
        children: [
            {
                name: 'user',
                path: ':id',
                component: (): Promise<RouteComponent> => import('../components/users/User.vue'),
            },
            {
                name: 'users-list',
                path: '',
                component: (): Promise<RouteComponent> => import('../components/users/UsersList.vue'),
            },
        ],
    },
    {
        name: 'exports',
        path: '/exports',
        component: (): Promise<RouteComponent> => import('../components/exports/ExportsView.vue'),
        meta: {
            permissions: ['FunderExporter'],
        },
        children: [
            {
                name: 'exports-list',
                path: '',
                component: (): Promise<RouteComponent> => import('../components/exports/ExportsList.vue'),
            },
            {
                name: 'exports-new',
                path: 'new',
                component: (): Promise<RouteComponent> => import('../components/exports/ExportsNew.vue'),
            },
        ],
    },
    {
        name: 'logout',
        path: '/logout',
        component: LogoutView,
    },
    {
        name: 'login',
        path: '/login',
        component: LoginView,
        meta: {
            public: true,
        },
    },
    {
        name: 'register',
        path: '/register',
        component: (): Promise<RouteComponent> => import('../components/auth/RegisterView.vue'),
        meta: {
            public: true,
        },
    },
    {
        name: 'forgot-password',
        path: '/forgot-password',
        component: (): Promise<RouteComponent> => import('../components/auth/ForgotPasswordView.vue'),
        meta: {
            public: true,
        },
    },
    {
        name: 'not-found',
        path: '/:pathMatch(.*)*',
        component: NotFoundView,
    },
]

export const createRouter = () => {
    const auth = useAuthStore()
    const appState = useAppStore()

    const router = createVueRouter({
        history: createWebHistory(import.meta.env.BASE_URL),
        routes,
        linkActiveClass: 'link-active',
        linkExactActiveClass: 'link-active-exact',
        scrollBehavior(_: any, __: any, savedPosition: any) {
            if (savedPosition) {
                return savedPosition
            } else {
                return { top: 0 }
            }
        },
    })

    router.beforeEach(async (to: any, from: any) => {
        try {
            const { meta } = to
            // Allow legacy token use
            if (meta.legacy) return true
            if (to.name === 'register' && to.query.email && to.query.token) {
                // Legacy portal user register
                return true
            }
            if (to.name === 'login' && from.name === 'register' && from.query.email && from.query.token) {
                // Block login redirect from legacy register
                return false
            }

            // Public routes are auth pages
            const isPublic = meta.public === true
            let loggedIn = !!auth.account

            // Logout on using register invite
            if (loggedIn && to.name === 'register') {
                await auth.logout()
                loggedIn = false
            }

            // Fetch app data if it doesn't exist
            if (loggedIn && !appState.appData) {
                const requests: Promise<void>[] = []
                if (!appState.appData) {
                    requests.push(appState.init())
                }
                await Promise.all(requests)
            }

            // Redirect to login if accessing private route
            if (!isPublic && !loggedIn) {
                return { name: 'login' }
            }

            // If logged in and try to access an auth route, redirect
            if (isPublic && loggedIn) {
                if (hasPermission(auth.account?.roles, ['FunderReferenceViewer'])) {
                    return { name: 'dashboard' }
                }
                return { name: 'search' }
            }

            // If route requires permission, and user doesn't have it
            // Redirect to dashboard
            if (loggedIn && (meta.permissions || meta.flags)) {
                const results = [
                    hasFlag(appState.appData.flags, meta.flags),
                    hasPermission(auth.account?.roles, meta?.permissions),
                ]

                if (!results.every((r) => r === true || r === null)) {
                    return { name: 'search' }
                }
            }

            if (loggedIn && !auth.account?.acceptedRetrofitTAndCs && to.query?.showRetrofitTAndCs !== 'y') {
                return { name: 'search', query: { showRetrofitTAndCs: 'y' } }
            }

            // Maintain query string parameters on property route
            if (to.name?.toString().startsWith('property') && from.query.uprn && !to.query.uprn) {
                return { ...to, query: from.query }
            }
            return true
        } catch (e: any) {
            appState.error = new HttpError(e.response?.status === 0 ? e.code : e.response?.status, e.message)
            return false
        }
    })

    router.afterEach(async (to: any, _: any, failure: any) => {
        if (!isNavigationFailure(failure)) {
            // Reset state
            appState.error = null
            appState.sidebarExpanded = false
            // Bootstrap override classes
            // In case user navigates whilst modal is open
            document.body?.classList.remove('modal-open')
            document.body?.removeAttribute('style')
        }
        if (to.name === 'logout') {
            try {
                await auth.logout()
            } catch {
                // @TODO Do nothing?
            }
        }
    })

    // New builds will sometimes cause current users with cached versions to request components no longer exist. Force a hard refresh if that happens
    router.onError((error) => {
        console.log(error)
        if (error.message.includes('Failed to fetch dynamically imported module')) {
            window.location.reload()
        }
    })

    return router
}
