<template>
    <LayoutAuth title="Two-Factor Authentication" :form="props.form" :rules="rules">
        <template #header>
            <p class="text-secondary lh-sm mt-4">
                <small>Protecting your account is our highest priority.</small>
            </p>
            <p class="text-secondary lh-sm">
                <small
                    >You will have been sent an email with a verification code. Please enter the code below to complete
                    the reset process, and scan the QR code with your authenticator app to complete the reset
                    process.</small
                >
            </p>
        </template>
        <template #default="{ v$, submitStage, busy, submitButton }">
            <form @submit.prevent="submitStage(handleSubmit)">
                <fieldset :disabled="busy">
                    <FormInput
                        id="emailCode"
                        v-model="emailCode"
                        label="Email Verification Code"
                        autocomplete="off"
                        :validator="v$.emailCode"
                        required
                    />
                    <div class="d-flex justify-content-center mt-4">
                        <QrcodeVue :value="totpKey" level="M" :size="200" />
                    </div>
                    <div class="mt-4">
                        Please scan the QR code below with your authenticator app and enter the two consecutive codes below (the second code will appear once the first code has timed out).
                    </div>
                    <FormInput
                        id="totpCode1"
                        v-model="totpCode1"
                        class="mt-4"
                        label="First Code"
                        :validator="v$.totpCode1"
                        required
                    />
                    <FormInput
                        id="totpCode2"
                        v-model="totpCode2"
                        class="mt-4"
                        label="Second Code"
                        :validator="v$.totpCode2"
                        required
                    />
                </fieldset>
                <div class="mt-4 d-flex justify-content-between">
                    <Component :is="submitButton">Authenticate</Component>
                </div>
            </form>
        </template>
        <template #footer>
            <RouterLink :to="{ name: 'login' }" class="text-body" @click.prevent="onReset">Back to Login</RouterLink>
        </template>
    </LayoutAuth>
</template>

<script setup lang="ts">
import { computed } from 'vue'
import { required, helpers } from '@vuelidate/validators'
import FormInput from '../form/FormInput.vue'
import LayoutAuth from '../layouts/LayoutAuth.vue'
import { useAuthStore } from '../../stores/auth'
import { useAuthForm, formEmit, formProp } from '../../utilities/auth'
import QrcodeVue from 'qrcode.vue'
import { useRouter } from 'vue-router'

const emit = defineEmits([formEmit])
const props = defineProps(formProp)
const totpKey = computed(
    () =>
        `otpauth://totp/TrustMark%20Property%20Checker:${encodeURIComponent(props.form?.email)}?secret=${
            auth.totpKey
        }&issuer=TrustMark&algorithm=SHA1`,
)

const { emailCode, totpCode1, totpCode2 } = useAuthForm(props, emit, ['emailCode', 'totpCode1', 'totpCode2'])

const router = useRouter()

const rules = computed(() => ({
    emailCode: {
        required: helpers.withMessage('Email verification code is required', required),
    },
    totpCode1: {
        required: helpers.withMessage('First code is required', required),
    },
    totpCode2: {
        required: helpers.withMessage('Second code is required', required),
    },
}))

const auth = useAuthStore()

const handleSubmit = async (form: any) => {
    await auth.completeReset(form.emailCode, form.totpCode1, form.totpCode2)
    await auth.login({
        username: form.email,
        password: form.password,
    })
    router.push({ name: 'dashboard' })
}

const onReset = () => {
    auth.$reset()
}
</script>
