import axios from 'axios'

// URLs
export const API_URL = import.meta.env.VITE_API_URL
export const FILE_API_URL = import.meta.env.VITE_FILE_API_URL

export const X_API_KEY = import.meta.env.VITE_X_API_KEY

// Clients
export const HTTP = axios.create({ baseURL: API_URL })
HTTP.defaults.withCredentials = true
export const FILE_HTTP = axios.create({ baseURL: FILE_API_URL })
FILE_HTTP.defaults.withCredentials = true
