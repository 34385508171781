<template>
    <div class="p-4 flex-grow-1 d-flex justify-content-center align-items-center">
        <div class="container-logout">
            <div class="text-center mb-5">
                <img src="@/assets/trustmark-logo-colour.png" class="img img-fluid mb-4" style="width: 200px" />
            </div>
            <div class="d-flex align-items-center justify-content-center gap-3">
                <span class="spinner-border" />
                <h4>Logging out...</h4>
            </div>
        </div>
    </div>
</template>

<script setup lang="ts"></script>

<style lang="scss">
.container-logout {
    width: 100%;
    max-width: 420px;
    margin-left: auto;
    margin-right: auto;
}
</style>
