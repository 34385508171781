<template>
    <PageHeader :title="auth.account?.funderOrganisationName || 'Dashboard'"> </PageHeader>
    <div v-if="stats" class="dashboard-section">
        <div class="entity-grid row">
            <div class="col-12 col-sm-6 col-xl-4 col-xxl-3">
                <div class="card text-body border-primary">
                    <div class="card-body text-center">
                        <div class="row">
                            <div class="col-3 d-flex align-items-center justify-content-center">
                                <i class="bi bi-bank text-primary fs-1"></i>
                            </div>
                            <div class="col-9 text-nowrap">
                                <div class="count">{{ stats.references }}</div>
                                {{ plural(stats.references, 'Funder Reference') }}
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            <div class="col-12 col-sm-6 col-xl-4 col-xxl-3">
                <div class="card text-body border-primary">
                    <div class="card-body text-center">
                        <div class="row">
                            <div class="col-3 d-flex align-items-center justify-content-center">
                                <i class="bi bi-cash text-primary fs-1"></i>
                            </div>
                            <div class="col-9 text-nowrap">
                                <div class="count">£{{ stats.totalValue.toLocaleString() }}</div>
                                Loan Value
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            <div class="col-12 col-sm-6 col-xl-4 col-xxl-3">
                <div class="card text-body border-primary">
                    <div class="card-body text-center">
                        <div class="row">
                            <div class="col-3 d-flex align-items-center justify-content-center">
                                <i class="bi bi-check text-primary fs-1"></i>
                            </div>
                            <div class="col-9 text-nowrap">
                                <div class="count">{{ stats.lodgedReferences }}</div>
                                Lodged References
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            <div class="col-12 col-sm-6 col-xl-4 col-xxl-3">
                <div class="card text-body border-primary">
                    <div class="card-body text-center">
                        <div class="row">
                            <div class="col-3 d-flex align-items-center justify-content-center">
                                <i class="bi bi-hourglass text-primary fs-1"></i>
                            </div>
                            <div class="col-9 text-nowrap">
                                <div class="count">{{ stats.unlodgedReferences }}</div>
                                Not Lodged References
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>

    <div class="dashboard-section">
        <h2 class="h2 my-4">Recently Viewed</h2>
        <div class="row">
            <div class="entity-grid row col">
                <div v-for="r in property.recentlyViewed" :key="r.accessedDt.toString()" class="col-12">
                    <PropertyThumb v-if="property.recentlyViewed?.length > 0" v-bind="r" />
                </div>
                <p v-if="property.recentlyViewed?.length == 0">You haven't searched any properties yet.</p>
            </div>
        </div>
    </div>
</template>

<script setup lang="ts">
import { useHead } from '@vueuse/head'
import PageHeader from '../PageHeader.vue'
import { usePropertyStore } from '../../stores/property'
import PropertyThumb from '../property/PropertyThumb.vue'
import { onMounted, ref } from 'vue'
import { useApi } from '../../stores/api'
import { useAuthStore } from '../../stores/auth'

useHead({ title: 'Dashboard • TrustMark | Property Checker Portal' })

const property = usePropertyStore()
const api = useApi()
const auth = useAuthStore()
const stats = ref<{ references: number; totalValue: number; lodgedReferences: number; unlodgedReferences: number }>()

const plural = (n: number, word: string, append = 's') => `${word}${n === 1 ? '' : append}`

onMounted(async () => {
    property.getRecentlyViewed()
    const response = await api.getDashboardStats()
    if (response.isSuccess) {
        stats.value = response.model
    }
})
</script>

<style lang="scss" scoped>
.dashboard-entities {
    border-bottom: 1px solid $border-color;
}

@include media-breakpoint-up(xl) {
    .dashboard {
        display: flex;
    }
}

.dashboard-section {
    padding: 1.5rem;

    &:not(:last-child) {
        border-bottom: 1px solid $border-color;
    }
}

.description {
    color: $secondary;
    font-size: 0.875em;
    line-height: 1.25;
}

.top-level {
    color: $secondary;
}

.count {
    font-size: 2rem;
    color: $primary;
}
</style>
