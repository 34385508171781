<template>
    <PageHeader title="Recently Viewed"> </PageHeader>

    <div class="dashboard-section">
        <div class="row">
            <div class="entity-grid row col">
                <div v-for="r in property.recentlyViewed" :key="r.accessedDt.toString()" class="col-12">
                    <PropertyThumb v-if="property.recentlyViewed?.length > 0" v-bind="r" />
                </div>
                <p v-if="property.recentlyViewed?.length == 0">You haven't searched any properties yet.</p>
            </div>
        </div>
    </div>
</template>

<script setup lang="ts">
import { useHead } from '@vueuse/head'
import PageHeader from '../PageHeader.vue'
import { usePropertyStore } from '../../stores/property'
import PropertyThumb from '../property/PropertyThumb.vue'
import { onMounted } from 'vue'

useHead({ title: 'Recently Viewed • TrustMark | Property Checker Portal' })

const property = usePropertyStore()

onMounted(async () => {
    property.getRecentlyViewed()
})
</script>

<style lang="scss" scoped>
.dashboard-entities {
    border-bottom: 1px solid $border-color;
}

@include media-breakpoint-up(xl) {
    .dashboard {
        display: flex;
    }
}

.dashboard-section {
    padding: 1.5rem;

    &:not(:last-child) {
        border-bottom: 1px solid $border-color;
    }
}

.description {
    color: $secondary;
    font-size: 0.875em;
    line-height: 1.25;
}

.top-level {
    color: $secondary;
}

.count {
    font-size: 2rem;
    color: $primary;
}
</style>
