import { createApp } from 'vue'
import { createHead } from '@vueuse/head'
import { createPinia } from 'pinia'
import axios from 'axios'
import { createRouter } from './router'
import { sentryPlugin } from './utilities/sentry'
import './styles/main.scss'
import App from './App.vue'
import { useAuthStore } from './stores/auth'

const app = createApp(App)
const head = createHead()
app.use(head)
const pinia = createPinia()
app.use(pinia)
const router = createRouter()
app.use(router)
app.use(sentryPlugin, { router })

app.mount('#app')

const auth = useAuthStore()
auth.$onAction(({ name, after }) => {
    if (name === 'logout') {
        after(async () => {
            // Clears account and recently added
            localStorage.clear()
            router.push({ name: 'login' })
        })
    }
})

// Version Handling
if (import.meta.env.PROD) {
    const versionDatetime = '__BUILDDATETIME__' // String replaced by Vite
    const setupVersionChecker = () => {
        let reloading = false
        const check = async () => {
            if (reloading) return
            try {
                const { data } = await axios.get('version.json', {
                    baseURL: '/',
                    params: { t: new Date().getTime() },
                })
                const currentVersion = Date.parse(versionDatetime)
                const nextVersion = Date.parse(data.datetime)
                if (nextVersion > currentVersion) {
                    reloading = true
                    window.location.reload()
                }
            } catch {
                // Nothing
            }
        }
        // Check on visibility change
        document.addEventListener('visibilitychange', () => {
            if (document.visibilityState === 'visible') {
                check()
            }
        })
        // Check every five minutes
        setInterval(check, 300000)
    }

    setupVersionChecker()
}
