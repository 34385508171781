import { defineStore } from 'pinia'
import { useApi } from './api'
import { useLocalStorage } from '@vueuse/core'

export interface AccountState {
    account: any
    user: any
    defaultFilters: any
}

export const useAccountStore = defineStore({
    id: 'account',
    state: () =>
        ({
            account: null,
            user: null,
            defaultFilters: useLocalStorage(
                'defaultFilters',
                {},
                {
                    serializer: {
                        read: (v) => (v ? JSON.parse(v) : {}),
                        write: (v) => JSON.stringify(v),
                    },
                },
            ),
        }) as AccountState,
    actions: {
        async init() {
            const api = useApi()
            try {
                const result = await api.throwOnError().getAccount()
                if (result) {
                    this.account = result.model
                }
            } catch {
                // Fail silently
            }
        },
    },
})
