import * as Sentry from '@sentry/vue'

export const sentryPlugin = {
    install(app: any, { router }: any) {
        const { VITE_ENV, VITE_BUILD_VERSION, VITE_SENTRY_DSN, VITE_SENTRY_DEBUG } = import.meta.env
        if (VITE_SENTRY_DSN && router) {
            Sentry.init({
                app,
                dsn: VITE_SENTRY_DSN,
                integrations: [Sentry.browserTracingIntegration({ router })],
                environment: VITE_ENV,
                tracesSampleRate: 1.0,
                debug: VITE_SENTRY_DEBUG === 'true' || VITE_SENTRY_DEBUG === true,
                release: VITE_BUILD_VERSION,
            })
        }
    },
}
