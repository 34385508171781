import { RemovableRef, useLocalStorage } from '@vueuse/core'
import { defineStore } from 'pinia'
import { useApi } from './api'
import { HttpError } from '../utilities/error'

export const VERSION = import.meta.env.VITE_BUILD_VERSION || ''

export interface RoleModel {
    key: string
    description: string
    dependentRoles: RoleModel[]
}

export interface AppState {
    version: string
    appData: null | any
    error: null | HttpError
    notifications: any[]
    sidebarExpanded: boolean
    sidebarMinimized: RemovableRef<boolean>
}

export const useAppStore = defineStore({
    id: 'app',
    state: () =>
        ({
            version: VERSION,
            appData: null,
            error: null,
            notifications: [],
            sidebarExpanded: false,
            sidebarMinimized: useLocalStorage('sidebar-minimized', false),
        }) as AppState,
    getters: {},
    actions: {
        async init() {
            const api = useApi()
            this.appData = await api.silently().throwOnError().getAppData()
        },
        addNotification(notification: any) {
            // Create a simple ID if not provided
            if (!notification.id) notification.id = new Date().valueOf()
            this.notifications.push(notification)
            if (notification.timeout) {
                setTimeout(() => this.removeNotification(notification.id), notification.timeout)
            }
            return notification.id
        },
        removeNotification(id: number) {
            const index = this.notifications.findIndex((n) => n.id === id)
            if (index > -1) {
                this.notifications.splice(index, 1)
            }
        },
    },
})
