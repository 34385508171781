<template>
    <LayoutAuth title="" :form="props.form">
        <template #default>
            <div class="d-flex align-items-center justify-content-center gap-3">
                <span class="spinner-border" />
                <h4>Loading...</h4>
            </div>
        </template>
    </LayoutAuth>
</template>

<script setup lang="ts">
import LayoutAuth from '../layouts/LayoutAuth.vue'
import { formProp } from '../../utilities/auth'
import { onMounted, onUnmounted } from 'vue'

const props = defineProps(formProp)

let timeout: ReturnType<typeof setTimeout>

// edge case when user has been forcefully logged out on a separate tab
// the user ends up stuck here. refreshing will put it back in correct state
onMounted(() => {
    timeout = setTimeout(() => {
        window.location.reload()
    }, 5000)
})

onUnmounted(() => {
    clearTimeout(timeout)
})
</script>
