<template>
    <div class="p-4 flex-grow-1 d-flex justify-content-center align-items-center">
        <div class="container-login">
            <div class="text-center mb-5">
                <img src="@/assets/trustmark-logo-colour.png" class="img img-fluid mb-4" style="width: 200px" />
                <h2 v-if="title" class="text-center">{{ title }}</h2>
                <slot name="header" />
            </div>

            <div v-if="error" class="alert alert-danger p-2 my-4 text-center" role="alert">
                <i class="bi bi-exclamation-triangle me-2" />{{ error }}
            </div>

            <slot
                :form="props.form"
                :v$="v$"
                :busy="busy"
                :submit-stage="submitStage"
                :submit-button="LayoutAuthSubmitButton"
            />

            <div class="mt-4 mb-5 text-center">
                <slot name="footer" />
            </div>
        </div>
    </div>
</template>

<script setup lang="ts">
import { provide, ref } from 'vue'
import useVuelidate from '@vuelidate/core'
import LayoutAuthSubmitButton from './LayoutAuthSubmitButton.vue'

const props = defineProps({
    form: {
        type: Object,
        default: () => ({}),
    },
    rules: {
        type: Object,
        default: () => ({}),
    },
    title: {
        type: String,
        default: '',
    },
})

const v$ = useVuelidate(props.rules, props.form)

const busy = ref(false)
provide('busy', busy)

const error = ref('')
const handleError = (e: any) => {
    const body = e.response?.data || {}
    error.value = body.reasons?.[0].reason || body.message || e.message || e.toString()
}

const submitStage = async (onSuccess: any) => {
    try {
        if (busy.value) return
        const valid = await v$.value.$validate()
        if (!valid) return
        busy.value = true
        await onSuccess(props.form, v$)
    } catch (e) {
        handleError(e)
    } finally {
        busy.value = false
    }
}
</script>

<style lang="scss">
.container-login {
    width: 100%;
    max-width: 420px;
    margin-left: auto;
    margin-right: auto;
}
</style>
