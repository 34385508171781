<template>
    <div ref="target" class="nav-account">
        <div class="py-2 px-3">
            <div class="d-flex justify-content-start align-items-center gap-3">
                <div class="nav-account-icon bi-person-circle"></div>
                <div class="overflow-hidden py-2">
                    <div v-if="accountHolderName" class="fw-bold text-truncate">{{ accountHolderName }}</div>
                    <div class="small text-truncate">{{ email }}</div>
                </div>
            </div>
        </div>
        <RouterLink class="nav-account-link" :to="{ name: 'logout' }"
            >Logout<i class="bi-box-arrow-right" />
        </RouterLink>
    </div>
</template>

<script setup lang="ts">
import { computed, ref } from 'vue'
import { onClickOutside } from '@vueuse/core'
import { useAccountStore } from '../../stores/account'
import { useAuthStore } from '../../stores/auth'

const accountStore = useAccountStore()
const authStore = useAuthStore()

const emit = defineEmits(['dismiss'])
const props = defineProps({
    dismissIgnore: {
        type: Array<any>(),
        default: () => [],
    },
})

const target = ref(null)
onClickOutside(target, (event) => emit('dismiss', event), { ignore: props.dismissIgnore })

const accountHolderName = computed(() => accountStore.account?.accountHolderName || 'TrustMark')
const email = computed(() => authStore.account?.accountName)
</script>

<style lang="scss" scoped>
.nav-account-icon {
    color: $primary;
    font-size: 3rem;
}
.nav-account {
    background-color: $white;
    position: absolute;
    right: 0;
    top: $navbar-height;
    box-shadow: $box-shadow;
    width: $drawer-width;
    line-height: $line-height-sm;
}

.nav-account-link,
.nav-account-account {
    text-decoration: none;
    padding: 0.75rem 1rem;
    line-height: 1;
    display: flex;
    align-items: center;
    justify-content: space-between;
    font-size: $btn-font-size;
    font-weight: $btn-font-weight;
}
.nav-account-link {
    color: $secondary;
    background-color: $light;
    border-top: 1px solid $gray-300;

    &:hover,
    &:focus {
        color: $primary;
    }
}

.nav-account-accounts {
    max-height: 12rem;
    overflow-y: scroll;
    .nav-account-link {
        color: $gray-700;
        background-color: $gray-300;
        border-top: 1px solid $gray-400;
        &:first-of-type {
            border-top-color: $gray-300;
        }
        &:hover,
        &:focus {
            color: $white;
            background-color: $primary;
        }
    }
}

.available-accounts {
    color: $primary;
}
</style>
