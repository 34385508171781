<template>
    <div class="sticky-header">
        <slot />
    </div>
</template>

<script setup lang="ts"></script>

<style lang="scss" scoped>
.sticky-header {
    position: sticky;
    top: $navbar-height;
    z-index: $zindex-sticky;
}
</style>
