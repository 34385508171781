<template>
    <StickyHeader class="bg-white">
        <div class="page-header px-4">
            <div class="py-3 d-flex justify-content-start align-items-center">
                <h1 class="h2">{{ title }}</h1>
                <div v-if="pasVersion" class="badge rounded-pill bg-dark ms-2">{{ pasVersion }}</div>
                <StatusBadge v-if="status" class="ms-2" :status="status || ''" />
                <h5 class="mb-0">{{ subtitle }}</h5>
            </div>
            <div v-if="references.length || $slots.default" class="page-header-content">
                <div v-if="references.length" class="page-header-references">
                    <span v-for="reference of references.filter((i: any) => i)" :key="reference">{{ reference }}</span>
                </div>
                <slot />
            </div>

            <div class="page-header-actions">
                <slot name="actions" />

                <div v-if="$slots.dropdown" class="dropdown">
                    <button
                        id="actions"
                        class="btn text-primary"
                        type="button"
                        data-bs-toggle="dropdown"
                        aria-expanded="false"
                    >
                        <i class="bi bi-three-dots-vertical" />
                    </button>
                    <ul class="dropdown-menu dropdown-menu-end" aria-labelledby="actions">
                        <slot name="dropdown" />
                    </ul>
                </div>
                <div class="d-flex align-items-start">
                    <img :src="auth.account?.funderLogoUrl" class="image-fluid" />
                </div>
            </div>
        </div>

        <div v-if="tabs.length" class="page-header-tabs px-4">
            <RouterLink v-for="tab of tabs" :key="tab.name" :to="{ name: tab.name }">
                {{ tab.title }}
            </RouterLink>
        </div>

        <hr />
        <Breadcrumbs v-if="path" :path="path" />
    </StickyHeader>
</template>

<script setup lang="ts">
import StickyHeader from './StickyHeader.vue'
import Breadcrumbs from './Breadcrumbs.vue'
import StatusBadge from './StatusBadge.vue'
import { useAuthStore } from '../stores/auth'

const auth = useAuthStore()

defineProps({
    title: {
        type: String,
        default: '',
    },
    subtitle: {
        type: String,
        default: '',
    },
    status: {
        type: String,
        default: '',
    },
    path: {
        type: Array,
        default: null,
    },
    references: {
        type: Array<any>(),
        default: () => [],
    },
    tabs: {
        type: Array<any>(),
        default: () => [],
    },
    pasVersion: {
        type: String,
        default: '',
    },
})
</script>

<style lang="scss">
.page-header {
    height: $page-header-height;
    display: flex;
    justify-content: space-between;
    align-items: flex-start;
    align-items: center;
    gap: 1rem;
}

.page-header-content {
    display: none;
    font-size: $font-size-sm;
    line-height: $line-height-sm;
    font-weight: $headings-font-weight;
    color: $secondary;
    flex-grow: 1;
    white-space: nowrap;
    padding: 0.5rem 0;
    text-align: left;
    margin-top: 0.5rem;
}

.page-header-references {
    > span {
        display: inline-block;
        vertical-align: middle;
        max-width: 12em;
        overflow: hidden;
        text-overflow: ellipsis;
        &:not(:first-of-type):before {
            content: '•';
            margin: 0 0.25rem;
        }
    }
}

.page-header-actions {
    display: flex;
    align-items: center;
    gap: 0.5rem;
    padding: 0.25rem 0;
    > button,
    > .dropdown > button {
        width: 2.25rem;
        height: 2.25rem;
        border-radius: 100%;
        display: flex;
        align-items: center;
        justify-content: center;
        > i {
            line-height: 1;
            font-size: 1.25rem;
            height: 1.25rem;
            width: 1.25rem;
        }
    }
}

@include media-breakpoint-up(md) {
    .page-header > div {
        width: auto;
    }
    .page-header-content {
        display: block;
        text-align: end;
        margin-top: 0;
    }
}

.page-header-tabs {
    width: 100%;
    margin-top: 0.5rem;
    display: flex;
    margin-bottom: -2px;
    white-space: nowrap;
    > a {
        text-decoration: none;
        color: $secondary;
        padding-bottom: 0.25rem;
        border-bottom: 3px solid transparent;
        margin-right: 1.5rem;
        &:last-child {
            margin-right: 0;
        }
        &:hover,
        &:focus {
            color: $primary;
        }
        &.link-active-exact {
            border-bottom-color: $primary;
            color: $primary;
        }
    }
}

@include media-breakpoint-down(md) {
    .page-header-tabs {
        overflow-x: scroll;
    }
}

.image-fluid {
    width: 100%;
    max-width: 250px;
    max-height: 50px;
    height: auto;
    margin: 0 auto;
}
</style>
