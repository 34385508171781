<template>
    <RouterLink class="card text-decoration-none text-body" :to="url">
        <div class="card-body">
            <div class="d-flex justify-content-between align-items-start mb-1">
                <h5 class="mb-0">{{ title || '—' }}</h5>
            </div>
            <div class="text-secondary fs-6">
                <div><i class="bi bi-geo-alt me-2" />{{ description }}</div>
            </div>
        </div>
        <div class="card-footer text-secondary fs-xxs">
            {{ accessedAt }}
        </div>
    </RouterLink>
</template>

<script setup lang="ts">
import { formatDistanceToNow } from 'date-fns'
import { computed } from 'vue'

const props = defineProps({
    accessedDt: { type: Date, required: true },
    description: { type: String, default: '' },
    title: { type: String, default: '' },
    url: { type: String, required: true },
})

const accessedAt = computed(() => {
    if (props.accessedDt) return `Last accessed ${formatDistanceToNow(props.accessedDt, { addSuffix: true })}`
    return 'Not yet accessed'
})
</script>
