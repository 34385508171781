<template>
    <Component :is="props.is" ref="el">
        <slot />
    </Component>
</template>

<script setup lang="ts">
import { Popover as bootstrapPopover } from 'bootstrap'
import { onMounted, onBeforeUnmount, ref, watch } from 'vue'

interface PopoverProps {
    is: string
    container?: string
    trigger?:
        | 'click'
        | 'focus'
        | 'hover'
        | 'manual'
        | 'click hover'
        | 'click focus'
        | 'hover focus'
        | 'click hover focus'
        | undefined
    placement?: 'auto' | 'top' | 'bottom' | 'left' | 'right'
    content?: string
    offset?: [number, number]
    disabled: boolean
}

const props = withDefaults(defineProps<PopoverProps>(), {
    is: 'div',
    container: 'body',
    trigger: 'click',
    placement: 'top',
    content: 'Content',
    offset: () => [0, 2],
    disabled: false,
})

const el = ref(null)
let popover: any = null

onMounted(() => {
    if (el.value) {
        popover = new bootstrapPopover(el.value, {
            container: props.container,
            trigger: props.trigger,
            placement: props.placement,
            content: props.content,
            offset: props.offset,
            html: true,
        })
    }
    if (props.disabled) {
        popover.disable()
    }
})

watch(
    () => props.disabled,
    (disabled) => {
        if (disabled) popover.disable()
        else popover.enable()
    },
)

onBeforeUnmount(() => popover?.dispose())
</script>
