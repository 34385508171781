<template>
    <div>
        <div class="px-4 py-2 bg-light">
            <nav aria-label="breadcrumb">
                <ol class="breadcrumb">
                    <li
                        v-for="item of props.path"
                        :key="item.key || item.title"
                        class="breadcrumb-item"
                        :class="{ active: !item.to }"
                    >
                        <RouterLink v-if="item.to" class="text-decoration-none" :to="item.to">{{
                            item.title
                        }}</RouterLink>
                        <span v-else>{{ item.title }}</span>
                    </li>
                </ol>
            </nav>
        </div>
        <slot name="footer">
            <hr />
        </slot>
    </div>
</template>

<script setup lang="ts">
const props = defineProps({
    path: {
        type: Array<any>(),
        required: true,
    },
})
</script>

<style lang="scss">
.drop-breadcrumbs-enter-active,
.drop-breadcrumbs-leave-active {
    height: 41px;
    overflow: hidden;
    transition: height 250ms ease;
}

.drop-breadcrumbs-enter-from,
.drop-breadcrumbs-leave-to {
    height: 0;
}
</style>
