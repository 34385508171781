import { useLocalStorage } from '@vueuse/core'
import { defineStore } from 'pinia'
import { HTTP, FILE_HTTP, X_API_KEY } from '../services/http.js'

export interface AuthState {
    account: any
    deviceValid: boolean
    mobileNumber: string | null
    smsCodeStatus: string | null
    smsToken: string | null
    usesTotp: boolean
    requiresReauth: boolean
    totpKey: string | null
}

export const useAuthStore = defineStore({
    id: 'auth',
    state: () =>
        ({
            account: useLocalStorage('account', null, {
                serializer: {
                    read: (v) => (v ? JSON.parse(v) : null),
                    write: (v) => JSON.stringify(v),
                },
            }),
            deviceValid: false,
            mobileNumber: null,
            smsCodeStatus: null,
            smsToken: null,
            usesTotp: false,
            requiresReauth: false, // if the account has been reset, then the user can get the totp key again
            totpKey: null,
        }) as AuthState,
    getters: {
        loggedIn(state) {
            return !!state.account
        },
    },
    actions: {
        async register(credentials: any) {
            return HTTP.post('/Register', credentials)
        },
        async totpRegister(credentials: any) {
            return HTTP.post('/Register/totp', credentials)
        },
        async verifyRegistration(credentials: any) {
            return HTTP.post('/Register/verify', credentials)
        },
        async verifyTotpRegistration(credentials: any) {
            return HTTP.post('/Register/verify/totp', credentials)
        },
        async login(user: any) {
            try {
                const result = await HTTP.post('/Auth/login', user)
                const { data } = result
                if (data.model.deviceValid) {
                    // After 2fa
                    this.account = data.model
                    this.requiresReauth = false
                    this.totpKey = null
                    this.smsCodeStatus = null
                    this.smsToken = null
                    this.usesTotp = false
                } else {
                    // Before 2fa
                    this.mobileNumber = data.model.maskedMobile
                    this.smsToken = data.model.accessToken
                    this.smsCodeStatus = 'required'
                    this.usesTotp = data.model.usesTotp
                    this.requiresReauth = data.model.requiresReauth
                    this.totpKey = data.model.totpKey
                }
                return data
            } catch (error: any) {
                this.account = null
                if (error?.response?.status === 403) {
                    throw new Error('You do not have permission to login.')
                }
                throw error
            }
        },
        async logout() {
            try {
                await HTTP.post('/Auth/logout')
            } catch {
                // Do nothing
            } finally {
                this.account = null
            }
        },
        async sendPasswordRecoveryCode(username: string) {
            return HTTP.post('/ResetPassword/sendcode', { username })
        },
        async sendEmailVerifyCode() {
            return HTTP.post('AuthDevice/sendmobilechangecode')
        },
        async resetPassword(payload: any) {
            return HTTP.post('/ResetPassword/reset-with-code', payload)
        },
        async refreshSession() {
            if (!this.account?.accountName || !this.account?.refreshToken) {
                throw new Error('Missing account details')
            }
            const result = await HTTP.post('/Auth/refresh', {
                username: this.account.accountName,
                refreshToken: this.account.refreshToken,
                isIntegrationAccount: this.account.isIntegrationAccount ?? false,
                thirdPartyIntegration: this.account.thirdPartyIntegration,
            })
            this.account = result.data.model
            return result.data
        },
        async sendSmsCode() {
            const result = await HTTP.post('AuthDevice/sendsms')
            this.smsCodeStatus = 'requested'
            return result.data
        },
        async verifySmsCode(smsCode: string) {
            const result = await HTTP.post('AuthDevice/verifysms', {
                smsCode,
            })
            if (result.data.isSuccess) {
                this.smsCodeStatus = 'verified'
                return result.data
            }
            throw new Error('Failed to verify SMS Code.')
        },
        async verifyTotpCode(totpCode: string) {
            const result = await HTTP.post('AuthDevice/verifytotp', {
                totpCode1: totpCode,
            })
            if (result.data.isSuccess) {
                this.smsCodeStatus = 'verified'
                return result.data
            }
            throw new Error('Failed to verify TOTP Code.')
        },
        async completeReset(emailCode: string, totpCode1: string, totpCode2: string) {
            const result = await HTTP.post('/AuthDevice/completereset', {
                confirmationCode: emailCode,
                totpCode1,
                totpCode2,
            })
            if (result.data.isSuccess) {
                return result.data
            }
            throw new Error('Failed to complete reset.')
        },
        async changeMobileNumber(mobileNumber: string, mobileChangeCode: string) {
            const result = await HTTP.post('/AuthDevice/changemobile', {
                mobileNumber,
                mobileChangeCode,
            })
            this.mobileNumber = result.data.maskedMobile || mobileNumber
            this.smsCodeStatus = 'requested'
            return result.data
        },
    },
})

const addIntercepts = (http: any, x_api_key: string | null) => {
    http.interceptors.request.use((config: any) => {
        const auth = useAuthStore()
        if (auth.account?.accessToken) {
            config.headers.Authorization = `Bearer ${auth.account.accessToken}`
        } else if (auth.smsToken) {
            config.headers.Authorization = `Bearer ${auth.smsToken}`
        }

        if (x_api_key) {
            config.headers['X-Api-Key'] = x_api_key
        }
        return config
    })

    // Auth error handler
    http.interceptors.response.use(
        (r: any) => r,
        async (error: any) => {
            const originalRequest = error.config
            if (
                error.response?.status === 401 &&
                !originalRequest.retry &&
                originalRequest.url !== '/Auth/logout' &&
                !originalRequest.url?.toLowerCase().includes('auth/refresh')
            ) {
                // Set retry to true to prevent a loop
                originalRequest.retry = true
                const auth = useAuthStore()
                try {
                    await auth.refreshSession()
                    return http(originalRequest)
                } catch {
                    // Logout
                    auth.logout()
                }
            }
            // Didn’t handle, throw
            throw error
        },
    )
}

addIntercepts(HTTP, X_API_KEY)
addIntercepts(FILE_HTTP, null)
