<template>
    <Component :is="stage" :form="form" @update:form="updateForm" />
    <small class="me-3 mb-2 float-right"
        ><i class="nav-link-icon bi bi-git" /><span class="nav-link-title ms-2">{{ appState.version }}</span></small
    >
</template>

<script setup lang="ts">
import { computed, reactive } from 'vue'
import { useHead } from '@vueuse/head'
import { useAuthStore } from '../../stores/auth'
import LoginUsernamePassword from './LoginUsernamePassword.vue'
import Login2FARequired from './Login2FARequired.vue'
import Login2FARequested from './Login2FARequested.vue'
import Login2FAVerify from './Login2FAVerify.vue'
import Login2FAReset from './Login2FAReset.vue'
import LoginBusy from './LoginBusy.vue'
import { useRoute } from 'vue-router'
import { useAppStore } from '../../stores/app'

useHead({ title: 'Login • TrustMark | Property Checker Portal' })

const auth = useAuthStore()
const appState = useAppStore()
const route = useRoute()

const form = reactive({
    email: decodeURIComponent(route.query.e?.toString() || ''),
    password: '',
    mobile: '',
    smsCode: '',
    emailCode: '',
    totpCode1: '',
    totpCode2: '',
})

const updateForm = (updated: any) => {
    if (updated.email) {
        form.email = updated.email
    }
    if (updated.password) {
        form.password = updated.password
    }
    if (updated.mobile) {
        form.mobile = updated.mobile
    }
    if (updated.smsCode) {
        form.smsCode = updated.smsCode
    }
    if (updated.emailCode) {
        form.emailCode = updated.emailCode
    }
    if (updated.totpCode1) {
        form.totpCode1 = updated.totpCode1
    }
    if (updated.totpCode2) {
        form.totpCode2 = updated.totpCode2
    }
}

const stage = computed(() => {
    if (auth.account || auth.smsCodeStatus === 'verified') {
        return LoginBusy
    }
    if (auth.smsCodeStatus === 'required') {
        if (auth.requiresReauth) {
            return Login2FAReset
        }
        if (auth.mobileNumber || auth.usesTotp) {
            return Login2FAVerify
        }
        return Login2FARequired
    }
    if (auth.smsCodeStatus === 'requested') {
        return Login2FARequested
    }
    return LoginUsernamePassword
})
</script>

<style lang="scss" scoped>
.float-right {
    text-align: right;
    color: $secondary;
    font-size: 0.7rem;
}
</style>
