import { computed } from 'vue'

export const stateEmit = 'update:state'
export const stateProp = {
    state: {
        type: Object,
        required: true,
    },
}

export const formEmit = 'update:form'
export const formProp = {
    form: {
        type: Object,
        required: true,
    },
}

export const useAuthForm = (props: any, emit: any, keys: any) => {
    const form = keys.reduce((acc: any, key: string) => {
        acc[key] = computed({
            get: () => props.form[key],
            set: (value) => emit('update:form', { [key]: value }),
        })
        return acc
    }, {})
    return form
}

export const hasFlag = (appFlags: any = {}, metaFlags: { [key: string]: boolean } | null | undefined = null) => {
    if (!metaFlags) return null
    for (const [key, value] of Object.entries(metaFlags)) {
        if (key in appFlags && appFlags[key] === value) {
            return true
        }
    }
    return false
}

export const hasPermission = (roles: string[] = [], permissions: string[] = []) => {
    if (!permissions?.length) return null
    for (const permission of permissions) {
        if (roles.includes(permission)) {
            return true
        }
    }
    return false
}
