<template>
    <LayoutAuth title="Two-Factor Authentication" :form="props.form" :rules="rules">
        <template #header>
            <p class="text-secondary lh-sm mt-4">
                <small>Protecting your account is our highest priority.</small>
            </p>
            <p class="text-secondary lh-sm">
                <small
                    >Request a verification code using the button below, then provide the code sent to your inbox along
                    with your new mobile number. You will then receive an SMS to confirm the new number is
                    correct.</small
                >
            </p>
        </template>
        <template #default="{ v$, submitStage, busy, submitButton }">
            <button type="button" class="btn btn-primary" @click.prevent="sendVerificationCode">
                Email Verification Code
            </button>
            <form @submit.prevent="submitStage(handleSubmit)">
                <FormInput
                    id="emailCode"
                    v-model="emailCode"
                    class="mt-4"
                    label="Received Verification Code"
                    :validator="v$.emailCode"
                    :disabled="busy || !codeSent"
                    required
                />
                <FormInput
                    id="mobile"
                    v-model="mobile"
                    class="mt-4"
                    label="Mobile Number for SMS Verification"
                    type="phone"
                    autocomplete="phone"
                    :validator="v$.mobile"
                    :disabled="busy || !codeSent"
                    required
                />
                <div class="mt-4 d-flex justify-content-between">
                    <Component :is="submitButton">Send SMS Verification Code</Component>
                </div>
            </form>
        </template>
        <template #footer>
            <RouterLink :to="{ name: 'login' }" class="text-body" @click.prevent="onReset">Back to Login</RouterLink>
        </template>
    </LayoutAuth>
</template>

<script setup lang="ts">
import { computed, ref } from 'vue'
import { required, helpers } from '@vuelidate/validators'
import FormInput from '../form/FormInput.vue'
import LayoutAuth from '../layouts/LayoutAuth.vue'
import { useAuthStore } from '../../stores/auth'
import { useAuthForm, formEmit, formProp } from '../../utilities/auth'
import { useAppStore } from '../../stores/app'

const app = useAppStore()

const emit = defineEmits([formEmit])
const props = defineProps(formProp)

const { mobile, emailCode } = useAuthForm(props, emit, ['mobile', 'emailCode'])

const rules = computed(() => ({
    mobile: {
        required: helpers.withMessage('Mobile number is required', required),
    },
    emailCode: {
        required: helpers.withMessage('Received Verification Code is required', required),
    },
}))

const codeSent = ref<null | string>(null)

const auth = useAuthStore()

const handleSubmit = async (form: any) => {
    await auth.changeMobileNumber(form.mobile, form.emailCode)
}

const onReset = () => {
    auth.$reset()
}

const sendVerificationCode = async () => {
    try {
        await auth.sendEmailVerifyCode()
        codeSent.value = 'requested'
        app.addNotification({
            type: 'success',
            message: 'An email has been sent with a verification code.',
        })
    } catch (error: any) {
        app.addNotification({
            type: 'danger',
            message: error.message,
        })
    }
}
</script>
