<template>
    <div
        class="x-sidebar-wrap flex-shrink-0"
        :class="{
            expanded: sidebarExpanded,
        }"
    >
        <div class="x-sidebar-background" @click="sidebarExpanded = false" />
        <div class="x-sidebar">
            <div class="x-sidebar-inner">
                <ul class="nav nav-pills flex-column mb-auto flex-nowrap">
                    <AppSidebarLink v-for="item of items" :key="item.to" v-bind="item" />
                </ul>
                <div class="x-sidebar-minimizer">
                    <button
                        class="btn bi"
                        :class="sidebarMinimized ? 'bi-chevron-double-right' : 'bi-chevron-double-left'"
                        @click.prevent="() => (sidebarMinimized = !sidebarMinimized)"
                    />
                </div>
                <ul class="nav nav-pills flex-column flex-nowrap">
                    <AppSidebarLink v-for="item of footerItems" :key="item.to" v-bind="item" />
                    <li v-if="account?.funderOrganisationName" class="version mt-2">
                        <a class="nav-link">
                            <i class="nav-link-icon bi bi-house" /><span class="nav-link-title">{{
                                account?.funderOrganisationName
                            }}</span>
                        </a>
                    </li>
                    <li class="version">
                        <a class="nav-link">
                            <i class="nav-link-icon bi bi-git" /><span class="nav-link-title">{{ version }}</span>
                        </a>
                    </li>
                </ul>
            </div>
        </div>
    </div>
</template>

<script setup lang="ts">
import { onKeyStroke } from '@vueuse/core'
import { storeToRefs } from 'pinia'
import { useRouter } from 'vue-router'
import { useAppStore } from '../../stores/app'
import { useAuthStore } from '../../stores/auth'
import { hasFlag, hasPermission } from '../../utilities/auth'
import AppSidebarLink from './AppSidebarLink.vue'

const { account } = storeToRefs(useAuthStore())
const { version, appData, sidebarExpanded, sidebarMinimized } = storeToRefs(useAppStore())

const router = useRouter()
const filterByAuth = (item: any) => {
    if (item.to) {
        try {
            const { meta } = router.resolve({ name: item.to })
            const results = [
                hasFlag(appData.value?.flags, meta?.flags),
                hasPermission(account.value.roles, meta?.permissions),
                !hasPermission(account.value.roles, meta?.inversePermissions),
            ]
            return results.every((r) => r === true || r === null)
        } catch {
            return false
        }
    }
    if (item.flags) {
        return hasFlag(appData.value?.flags, item.flags) !== false
    }
    return true
}

const items = [
    { icon: 'speedometer2', to: 'dashboard', title: 'Dashboard' },
    { icon: 'search', to: 'search', title: 'Property Search' },
    { icon: 'speedometer2', to: 'recently-viewed', title: 'Recently Viewed' },
    { icon: 'bank', to: 'funder-references-list', title: 'Funder References' },
    { icon: 'people', to: 'users-list', title: 'Users' },
    { icon: 'cloud-download', to: 'exports-list', title: 'Data Exports' },
].filter(filterByAuth)

const footerItems = [
    {
        icon: 'person',
        to: 'account',
        title: 'Account',
    },
    { icon: 'box-arrow-right', to: 'logout', title: 'Logout' },
].filter(filterByAuth)

onKeyStroke('Escape', () => {
    if (sidebarExpanded.value) {
        sidebarExpanded.value = false
    }
})
</script>

<style lang="scss" scoped>
.nav {
    width: 100%;
    padding-left: 1rem;
    padding-right: 1rem;
    transition: 200ms ease padding;
    > li {
        width: 100%;
    }
}
.nav-link {
    width: 100%;

    white-space: nowrap;
    color: $gray-700;
    &:not(.active) {
        &:hover,
        &:focus {
            color: $black;
        }
    }
}

.nav-link-icon {
    font-size: $font-size-base;
}

.nav-link-title {
    margin-left: 0.5em;
}

.nav-pills {
    overflow-y: auto;
}
</style>
<style lang="scss">
.x-sidebar-wrap {
    visibility: hidden;
    opacity: 0;
    transition:
        visibility 0s,
        opacity 200ms ease;
    position: fixed;
    top: 0;
    bottom: 0;
    right: 0;
    z-index: $zindex-sidebar;
    width: 100%;
    &.expanded {
        visibility: visible;
        opacity: 1;
        .x-sidebar {
            transform: translateX(0);
            opacity: 1;
        }
    }
}

.x-sidebar-background {
    position: absolute;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    width: 100%;
    height: 100%;
    background: rgba(0, 0, 0, 0.5);
}

.x-sidebar {
    height: 100%;
    padding-top: $navbar-height;
    background-color: $gray-100;
    color: $gray-200;
    position: absolute;
    right: 0;
    top: 0;
    bottom: 0;
    width: 50%;
    max-width: 500px;
    box-shadow: inset 1px 0 0 $border-color;
    opacity: 0;
    transform: translateX(10%);
    transition: 200ms ease transform;
}

.x-sidebar-inner {
    padding-top: 1rem;
    padding-bottom: 1rem;
    height: 100%;
    width: 100%;
    display: flex;
    flex-direction: column;
}

.x-sidebar-minimizer {
    display: none;
    position: relative;
    margin-bottom: 1rem;
    > button {
        padding: 0;
        color: $gray-500;
        width: 2rem;
        height: 2rem;
        border-radius: $border-radius-pill;
        border: 1px solid $border-color;
        position: absolute;
        bottom: 0;
        right: 0;
        transform: translateX(50%);
        background-color: $light;
        &:hover,
        &:focus {
            border-color: $primary;
            background-color: $primary;
            color: $white;
        }
    }
}

@include media-breakpoint-up(lg) {
    .x-sidebar-wrap {
        transition: none;
        opacity: 1;
        visibility: visible;
        left: 0;
        right: auto;
        width: $sidebar-width;
        transition: 200ms ease width;
    }
    .sidebar-minimized,
    .drawer-active {
        .x-sidebar-wrap {
            width: $sidebar-width-mini;
            .nav {
                padding-left: 0.5rem;
                padding-right: 0.5rem;
            }
            .nav-link-title {
                display: none;
            }
        }
    }
    .drawer-active {
        .x-sidebar-minimizer {
            display: none;
        }
    }
    .x-sidebar {
        transform: none;
        transition: none;
        opacity: 1;
        width: 100%;
        max-width: 100%;
        box-shadow: inset -1px 0 0 $border-color;
    }
    .x-sidebar-minimizer {
        display: block;
    }
}

.version {
    .nav-link-icon {
        font-size: 0.7rem;
    }

    .nav-link,
    .nav-link:not(.active):hover {
        color: $secondary;
        font-size: 0.7rem;
    }
}
</style>
