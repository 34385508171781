<template>
    <LayoutAuth title="Login to your account" :form="props.form" :rules="rules">
        <template #default="{ v$, submitStage, busy, submitButton }">
            <form @submit.prevent="submitStage(handleSubmit)">
                <fieldset :disabled="busy">
                    <FormInput
                        id="email"
                        v-model="email"
                        label="Email Address"
                        type="email"
                        autocomplete="email"
                        :validator="v$.email"
                        required
                    />
                    <FormInput
                        id="password"
                        v-model="password"
                        class="mt-4"
                        label="Password"
                        type="password"
                        autocomplete="password"
                        :validator="v$.password"
                        :disabled="busy"
                        required
                    />
                </fieldset>

                <div class="mt-4 d-flex justify-content-between">
                    <Component :is="submitButton">Login</Component>
                </div>
            </form>
        </template>

        <template #footer>
            <RouterLink :to="{ name: 'register' }" class="text-body">Register from an Invite</RouterLink>
            <span class="mx-2">•</span>
            <RouterLink :to="{ name: 'forgot-password' }" class="text-body">Forgot Password</RouterLink>
        </template>
    </LayoutAuth>
</template>

<script setup lang="ts">
import { computed } from 'vue'
import { useRouter } from 'vue-router'
import { required, email as isEmail, helpers } from '@vuelidate/validators'
import FormInput from '../form/FormInput.vue'
import LayoutAuth from '../layouts/LayoutAuth.vue'
import { useAuthStore } from '../../stores/auth'
import { useAuthForm, formEmit, formProp } from '../../utilities/auth'

const emit = defineEmits([formEmit])
const props = defineProps(formProp)

const { email, password } = useAuthForm(props, emit, ['email', 'password'])

const rules = computed(() => ({
    email: {
        required: helpers.withMessage('Email address is required', required),
        isEmail,
    },
    password: {
        required: helpers.withMessage('Password is required', required),
    },
}))

const auth = useAuthStore()
const router = useRouter()

const handleSubmit = async (form: any) => {
    await auth.login({
        username: form.email,
        password: form.password,
    })
    router.push({ name: 'dashboard' })
}
</script>
